import { extend, localize } from "vee-validate";
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import es from "vee-validate/dist/locale/es.json";
import ca from "vee-validate/dist/locale/ca.json";
import de from "vee-validate/dist/locale/de.json";

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorConfirmPassword,
  validatorCreditCard,
  validatorCheckinTime,
  validatorCheckoutTime,
  validatorPhone,
  validatorPolicy,
  validatorPastDate
} from "./validators";

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend("required", rule_required);

export const email = extend("email", rule_email);

export const min = extend("min", rule_min);

export const confirmed = extend("confirmed", rule_confirmed);

export const regex = extend("regex", rule_regex);

export const between = extend("between", rule_between);

export const alpha = extend("alpha", rule_alpha);

export const integer = extend("integer", rule_integer);

export const digits = extend("digits", rule_digits);

export const alphaDash = extend("alpha-dash", rule_alpha_dash);

export const alphaNum = extend("alpha-num", rule_alpha_num);

export const length = extend("length", rule_length);

export const positive = extend("positive", {
  validate: validatorPositive,
  message: "Please enter positive number!"
});

export const credit = extend("credit-card", {
  validate: validatorCreditCard,
  message: "It is not valid credit card!"
});

export const password = extend("password", validatorPassword);

export const confirmpassword = extend(
  "confirmpassword",
  validatorConfirmPassword
);

export const url = extend("url", {
  validate: validatorUrlValidator,
  message: "URL is invalid"
});

export const checkin = extend("checkin", validatorCheckinTime);

export const checkout = extend("checkout", validatorCheckoutTime);

export const phone = extend("phone", validatorPhone);

export const policy = extend("policy", validatorPolicy);

export const pastdate = extend("pastdate", validatorPastDate);

// Install English, Spanish & Catalan localizations.
localize({
  de: {
    messages: de.messages,
    names: {
      accommodationArrivalTime: "Ankunftszeit der Unterkunft",
      accommodationDepartureTime: "Abreisezeit der Unterkunft",
      arrivalFlightNumber: "Flugnummer der Ankunft",
      departureFlightNumber: "Flugnummer der Abreise",
      email: "E-Mail",
      password: "Passwort",
      newPassword: "Neues Passwort",
      confirmPassword: "Passwort bestätigen",
      phone: "Telefonnummer",
      policy: "Nutzungsbedingungen",
      idIssueDate: "Ausstellungsdatum des Ausweises",
      birthdate: "Geburtsdatum"
    },
    fields: {
      accommodationArrivalTime: {
        checkin: "Die Check-in-Zeit muss ab 16 Uhr sein"
      },
      accommodationDepartureTime: {
        checkout: "Die Check-out-Zeit darf nicht später als 10 Uhr sein"
      },
      currentPassword: {
        required: "Das aktuelle Passwortfeld ist erforderlich",
        password:
          "Das Passwort muss mindestens 6 Zeichen lang sein und einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten"
      },
      newPassword: {
        password:
          "Das Passwort muss mindestens 6 Zeichen lang sein und einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten"
      },
      confirmPassword: {
        confirmpassword: "Die Passwörter stimmen nicht überein"
      },
      idIssueDate: {
        pastdate:
          "Das Ausstellungsdatum des Ausweises muss in der Vergangenheit liegen"
      },
      birthdate: {
        pastdate: "Das Geburtsdatum muss in der Vergangenheit liegen"
      },
      phone: {
        phone: "Die Telefonnummer ist ungültig"
      },
      policy: {
        policy:
          "Sie müssen die Datenschutzrichtlinie und die Nutzungsbedingungen akzeptieren"
      }
    }
  },
  es: {
    messages: es.messages,
    names: {
      accommodationArrivalTime: "Hora de llegada al alojamiento",
      accommodationDepartureTime: "Hora de salida del alojamiento",
      arrivalFlightNumber: "Nº de vuelo de llegada",
      departureFlightNumber: "Nº de vuelo de salida",
      email: "Correo electrónico",
      password: "Contraseña",
      newPassword: "Nueva contraseña",
      confirmPassword: "Confirma contraseña",
      phone: "Nº teléfono",
      policy: "Términos y Condiciones",
      idIssueDate: "Fecha de expedición",
      birthdate: "Fecha de nacimiento"
    },
    fields: {
      accommodationArrivalTime: {
        checkin: "La hora de entrada debe ser a partir de las 16h"
      },
      accommodationDepartureTime: {
        checkout: "La hora de salida no puede ser posterior a las 10h"
      },
      currentPassword: {
        required: "El campo Contraseña actual es obligatorio",
        password:
          "La contraseña debe tener al menos 6 caracteres con una mayúscula, una minúscula y un número"
      },
      newPassword: {
        password:
          "La contraseña debe tener al menos 6 caracteres una mayúscula, una minúscula y un número"
      },
      confirmPassword: {
        confirmpassword: "Las contraseñas no coinciden"
      },
      idIssueDate: {
        pastdate: "La fecha de expedición debe ser una fecha pasada"
      },
      birthdate: {
        pastdate: "La fecha de nacimiento debe ser una fecha pasada"
      },
      phone: {
        phone: "El nº de teléfono no es válido"
      },
      policy: {
        policy:
          "Debes aceptar la Política de Privacidad y los Términos y Condiciones"
      }
    }
  },
  ca: {
    messages: ca.messages,
    names: {
      accommodationArrivalTime: "Hora d'arribada a l'allotjament",
      accommodationDepartureTime: "Hora de sortida de l'allotjament",
      arrivalFlightNumber: "Nº de vol d'arribada",
      departureFlightNumber: "Nº de vol de partida",
      email: "Correu electrònic",
      password: "Clau d'accés",
      newPassword: "Nova clau d'accés",
      confirmPassword: "Confirma clau d'accés",
      phone: "Nº telèfon",
      policy: "Termes i Condicions",
      idIssueDate: "Data d'expedició",
      birthdate: "Data de naixement"
    },
    fields: {
      accommodationArrivalTime: {
        checkin: "L'hora d'entrada ha de ser a partir de les 16h"
      },
      accommodationDepartureTime: {
        checkout: "L'hora de sortida no pot ser posterior a les 10h"
      },
      currentPassword: {
        required: "El camp Clau d'accés actual és obligatori",
        password:
          "La clau d'accés ha de tenir almenys 6 caràcters amb una majúscula, una minúscula i un número"
      },
      newPassword: {
        password:
          "La clau d'accés ha de tenir almenys 6 caràcters amb una majúscula, una minúscula i un número"
      },
      confirmPassword: {
        confirmpassword: "Les claus d'accés no coincideixen"
      },
      idIssueDate: {
        pastdate: "La data d'expedició ha de ser una data passada"
      },
      birthdate: {
        pastdate: "La data de naixement ha de ser una data passada"
      },
      phone: {
        phone: "El nº de telèfon no és vàlid"
      },
      policy: {
        policy:
          "És necessari acceptar la Política de Privacitat i els Termes i Condicions"
      }
    }
  },
  en: {
    messages: en.messages,
    names: {
      accommodationArrivalTime: "Accommodation arrival time",
      accommodationDepartureTime: "Accommodation departure time",
      arrivalFlightNumber: "Arrival flight nº",
      departureFlightNumber: "Departure flight nº",
      email: "Email",
      password: "Password",
      newPassword: "New password",
      confirmPassword: "Confirm password",
      phone: "Phone number",
      policy: "Terms & Conditions",
      idIssueDate: "ID issue date",
      birthdate: "Birthdate"
    },
    fields: {
      accommodationArrivalTime: {
        checkin: "The check-in time must be from 16h"
      },
      accommodationDepartureTime: {
        checkout: "The check out time can not be later than 10h"
      },
      currentPassword: {
        required: "The Current password field is required",
        password:
          "Password must be at least 6 characters with one uppercase, one lowercase and one number"
      },
      newPassword: {
        password:
          "Password must be at least 6 characters with one uppercase, one lowercase and one number"
      },
      confirmPassword: {
        confirmpassword: "The passwords do not match"
      },
      idIssueDate: {
        pastdate: "The ID issue date must be a past date"
      },
      birthdate: {
        pastdate: "The birthdate must be a past date"
      },
      phone: {
        phone: "The phone number is not valid"
      },
      policy: {
        policy: "You must accept the Privacy Policy and the Terms & Conditions"
      }
    }
  }
});
